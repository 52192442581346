import {BlogPostPage} from "collections/blog/BlogPostPage";
import {graphql} from "gatsby";
import React from "react";

export default function ContentfulBlogPost({data, index, location, pageContext}) {
    const {
        authors,
        title,
        heroImage,
        synopsis,
        category,
        bodyRichText,
        seo,
        slug,
        publishDate,
        tags,
        updatedAt: dateModified,
    } = data.contentfulBlogPost;

// const articles = data?.allContentfulBlogPost.nodes;
// const articlesPageInfo = data?.allContentfulBlogPost.pageInfo;
// const articlesNext = data?.allContentfulBlogPost;
// const articlesPrev = data?.allContentfulBlogPost.edges.previous;
//
// console.log("Articles: ",articles);
// console.log("articlesPageInfo: ",articlesPageInfo);
// console.log("location: ",location);
// console.log("Page Context: ",pageContext);
// console.log("articlesNext",articlesNext);
// console.log("articlesPrev",articlesPrev);

    const blogPostProps = {
        authors,
        title,
        heroImage,
        synopsis,
        category,
        bodyRichText,
        seo,
        slug,
        publishDate,
        dateModified,
        location,
        tags
    }

    return (
      <BlogPostPage {...blogPostProps} />
    )
}

export const pageQuery = graphql`
  query($slug : String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      id
      slug
      title
      authors {
        contentful_id
        name
        position
      }
      bodyRichText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            localFile {
              extension
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF],
                  quality: 70
                )
              }
            }
            publicUrl
          }
          ... on ContentfulPage {
            __typename
            contentful_id
            title
            slug
            category {
              slug
            }
          }
          ... on ContentfulBlogPost {
            contentful_id
            __typename
            title
            slug
          }
          ... on ContentfulProduct {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
      synopsis {
        internal {
          content
        }
      }
      category {
        slug
        title
      }
      tags {
        contentful_id
        slug
        title
      }
      publishDate(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      updatedAt(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      heroImage { ...ContentfulImage }
      seo { ...ContentfulSEO }
    }
  }
`;